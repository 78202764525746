import React from "react"
import PropTypes from "prop-types"
import BlockContent from "@sanity/block-content-to-react"
import Photo from "components/Photo"

const Post = ({ title, photos, content }) => (
  <div
    css={{
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      margin: "50px auto",
      maxWidth: 800,
      gridGap: 30,
      padding: "0 20px",
    }}
  >
    <h2 css={{ textAlign: "center", gridColumn: "3 / -3" }}>{title}</h2>

    <div
      css={{
        gridColumn: "1 / -1",
      }}
    >
      {photos.map((props, index) => (
        <Photo key={index} {...props} className="cover-photo" />
      ))}
    </div>

    {content && (
      <BlockContent
        css={{ gridColumn: "3 / -3" }}
        blocks={content}
        renderContainerOnSingleChild
      />
    )}
  </div>
)

export default Post

Post.propTypes = {
  title: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(PropTypes.shape(Photo.propTypes)),
  content: PropTypes.array,
}
