import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Post from "components/Post"

const BlogHomepage = ({ data }) => {
  const { posts } = data

  return (
    <>
      {posts.nodes.map((props, index) => (
        <Post key={index} {...props} />
      ))}
    </>
  )
}

export default BlogHomepage

BlogHomepage.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape(Post.propTypes)),
    }),
  }),
}

export const query = graphql`
  fragment PersonFields on SanityPerson {
    _id
    alias
    url
    slug {
      current
    }
    options {
      buildPage
    }
  }

  {
    posts: allSanityPost {
      nodes {
        title
        photos {
          caption
          author {
            ...PersonFields
          }
          subjects {
            ...PersonFields
          }
          image {
            ...Image
            ...ImagePresentationData
          }
          nsfw
        }
        content: _rawContent
      }
    }
  }
`
